import request from "@/untils/request.js";
//获取热门直播
export function getHomeData(query) {
  return request({
    url: "/live/index/hotlive",
    method: "get",
    params: query,
  });
}
///获取推荐赛事列表 (type 0-全部 1-足球 2-篮球)
export function homeMatchRecList(type) {
  return request({
    url: "/live/index/matchRecList/" + type,
    method: "get",
    params: {
      platform: 0,
    },
  });
}
//获取app基础配置信息
export function getInit() {
  return request({
    url: "/live/config/init",
    method: "get",
    params: {
      platform: 0,
    },
  });
}
//获取首页轮播广告
export function getAdsList() {
  return request({
    url: "/live/ads/getHomeAdList",
    method: "get",
    params: {
      platform: 0,
    },
  });
}