<template>
  <div class="footballMatch">
    <div
      class="footballMatch_item"
      @click="
        router.push({
          name: 'Course',
          query: {
            matchId: props.item.id,
            type: 2,
          },
        })
      "
    >
      <div class="footballMatch_item_top">
        <div class="footballMatch_item_top_left">
          <div class="footballMatch_item_top_left_name" :style="{ background: getColor() }">
            {{ props.item.competitionNameZh }}
          </div>
          <div class="footballMatch_item_top_left_time">{{ changeTime(props.item.matchTime) }}</div>
        </div>

        <div class="footballMatch_item_top_right">{{ getStatusText() }}</div>
      </div>
      <div class="footballMatch_item_bottom">
        <img
          @click.stop="cancel"
          src="@/assets/img/icon/save_a.png"
          alt=""
          v-if="props.item.isCollect == 1"
          class="footballMatch_item_bottom_left"
        />
        <img
          @click.stop="collect"
          v-else
          src="@/assets/img/icon/save.png"
          alt=""
          class="footballMatch_item_bottom_left"
        />
        <div class="footballMatch_item_bottom_middle">
          <div class="middle_item">
            <div class="team">{{ props.item.homeNameZH }}</div>
            <div class="course_box">
              <div class="course_item">
                {{ props.item.homeOneScore }}
              </div>
              <div class="course_item">
                {{ props.item.homeTwoScore }}
              </div>
              <div class="course_item">
                {{ props.item.homeThreeScore }}
              </div>
              <div class="course_item">
                {{ props.item.homeFourScore }}
              </div>
            </div>
            <div class="last_scroe">
              {{
                props.item.homeOneScore +
                props.item.homeTwoScore +
                props.item.homeThreeScore +
                props.item.homeFourScore
              }}
            </div>
          </div>
          <div class="middle_item">
            <div class="team">{{ props.item.awayNameZH }}</div>
            <div class="course_box">
              <div class="course_item">
                {{ props.item.awayOneScore }}
              </div>
              <div class="course_item">
                {{ props.item.awayTwoScore }}
              </div>
              <div class="course_item">
                {{ props.item.awayThreeScore }}
              </div>
              <div class="course_item">
                {{ props.item.awayFourScore }}
              </div>
            </div>
            <div class="last_scroe">
              {{
                props.item.awayOneScore +
                props.item.awayTwoScore +
                props.item.awayThreeScore +
                props.item.awayFourScore
              }}
            </div>
          </div>
        </div>
        <div class="imgs" v-if="props.item.anchors && props.item.anchors.length > 0">
          <div class="avatar_" v-for="(item, i) in props.item.anchors" :key="i">
            <img
              :src="filesCdnDomain + item.avatar"
              alt=""
              v-if="item.avatar"
              class="footballMatch_item_bottom_right"
              @click.stop="goLiving(item)"
            />
            <img
              src="@/assets/img/icon/avart.png"
              alt=""
              class="footballMatch_item_bottom_right"
              v-else
              @click.stop="goLiving(item)"
            />
          </div>
        </div>
        <img
          src="@/assets/img/icon/icon5.png"
          alt=""
          class="footballMatch_item_bottom_right"
          v-else-if="props.item.isAnimation == 1"
        />
        <img
          src="@/assets/img/icon/icon6.png"
          alt=""
          class="footballMatch_item_bottom_right"
          v-else-if="props.item.isLive == 1 && props.item.anchors.length == 0"
        />
      </div>
    </div>
  </div>
</template>
<script setup name="BasketballMatch">
import { useRouter } from "vue-router";
import { showFailToast, showSuccessToast, showConfirmDialog } from "vant";
import { addCollect, cancelCollect } from "@/assets/api/match";
import { useStore } from "vuex";
import { onMounted, ref, computed } from "vue";
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const isLogin = ref(localStorage.getItem("isLogin"));
const store = useStore();
const router = useRouter();
const props = defineProps({
  j: Number,
  item: Object,
});
function getColor() {
  if (props.j == 0 || props.j % 4 == 0) return "#6C56C2";
  if (props.j % 4 == 1) return "#659FF6";
  if (props.j % 4 == 2) return "#7FC256";
  if (props.j % 4 == 3) return "#DC8E46";
}
function changeTime(time) {
  let date = new Date(parseInt(time * 1000));
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let M = date.getMinutes();
  M = M < 10 ? "0" + M : M;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  let dateTime = h + ":" + M;
  return dateTime;
}
function formatSeconds(time) {
  if (!time) return "";
  let result = "";

  if (time < 60) {
    let s = parseInt(time) < 10 ? "0" + parseInt(time) : parseInt(time);

    result = "00:" + s;
  } else if (time < 3600) {
    let m = parseInt(time / 60);
    let s = parseInt(time % 60);

    result = (m < 0 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);
  }

  return result;
}
function collect() {
  if (!isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {
        // on cancel
      });
    return;
  }
  addCollect({ type: 2, matchId: props.item.id }).then((res) => {
    showSuccessToast("收藏成功");
    props.item.isCollect = 1;
  });
}
function cancel() {
  if (!isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {
        // on cancel
      });
    return;
  }
  cancelCollect({ type: 2, matchId: props.item.id }).then((res) => {
    showSuccessToast("已取消收藏");
    props.item.isCollect = 0;
  });
}
function goLiving(item) {
  if (item.isLive == 0) {
    showFailToast("主播暂未开播");
    return;
  }
  router.push({
    name: "Living",
    query: {
      id: item.uid,
    },
  });
}
function getStatusText() {
  switch (props.item.status) {
    case 1:
      return "未";

    case 2:
      return "第一节" + formatSeconds(props.item.remainingTime) + "";

    case 3:
      return "第一节完";

    case 4:
      return "第二节" + formatSeconds(props.item.remainingTime);

    case 5:
      return "第二节完";

    case 6:
      return "第三节" + formatSeconds(props.item.remainingTime);

    case 7:
      return "第三节完";

    case 8:
      return "第四节" + formatSeconds(props.item.remainingTime);

    case 9:
      return "加时";

    case 10:
      return "完";

    case 1:
      return "中断";

    case 12:
      return "取消";

    case 13:
      return "延期";

    case 14:
      return "腰斩";

    case 5:
      return "待定";

    default:
      return "";
  }
}
</script>

<style scoped>
.footballMatch {
  margin-top: 2.66vw;
}
.footballMatch_item {
  border-radius: 8px;
  background: #fff;
  padding: 6px;
}
.footballMatch_item_top {
  border-bottom: 1px solid #f3f5f6;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
}
.footballMatch_item_top_left {
  width: 36.8vw;
  display: flex;
  align-items: center;
}
.footballMatch_item_top_left_name {
  height: 4.8vw;
  font-size: 2.66vw;
  color: #fff;
  border-radius: 4px;
  width: 14.93vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px;
}
.footballMatch_item_top_left_time {
  font-size: 2.93vw;
  font-weight: 400;
  color: #a9a9a9;
}

.footballMatch_item_top_right {
  color: #a9a9a9;
  font-size: 2.93vw;
  font-weight: 400;
}

.footballMatch_item_bottom {
  display: flex;
  align-items: center;
  padding: 3.73vw 0 1.06vw;
}
.footballMatch_item_bottom_left {
  width: 5.33vw;
  height: 5.33vw;
}
.footballMatch_item_bottom_middle {
  flex: 1;
  margin: 0 5.33vw 0 5.33vw;
}
.middle_item {
  display: flex;
  align-items: center;
  margin-top: 6px;
}
.team {
  width: 21.86vw;
  color: #222;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.course_box {
  display: flex;
  align-items: center;
  margin-left: 4.8vw;
  flex: 1;
}
.course_item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #6b7280;
  font-size: 11px;
  font-weight: 400;
  line-height: 3.73vw;
  background-color: #f9fafb;
  border-radius: 4px;
  width: 5.33vw;
  height: 4.8vw;
}

.last_scroe {
  color: #da8b15;
  font-size: 3.2vw;
  font-weight: 500;
  line-height: 4.26vw;
}
.avatar_ .footballMatch_item_bottom_right {
  border-radius: 100%;
}
.footballMatch_item_bottom_right {
  width: 5.33vw;
  height: 5.33vw;
}
.imgs {
  display: flex;
  align-items: center;
}
.avatar_ {
  position: relative;
  z-index: 1;
  width: 5.33vw;
  height: 5.33vw;
  border-radius: 100%;
  border: 2px solid #fff;
}

.avatar_ {
  margin-left: -4px;
  z-index: 0;
}
</style>
<style>
/*修改滚动条样式*/
.middle_item:nth-child(1) {
  margin-top: 0 !important;
}
.qp::-webkit-scrollbar {
  display: none;
}
</style>
