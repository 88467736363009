<template>
  <div id="nav"></div>
  <router-view />
</template>
<script setup>
import { getInit } from "@/assets/api/home";
import { getUserInfo } from "@/assets/api/user";
import { getTxImSign, getSensitiveWords } from "@/assets/api/im";
import { onMounted, ref, computed, onBeforeMount, nextTick, watchEffect, watch } from "vue";
import { useStore } from "vuex";
import { chat } from "@/untils/tim";
import { useRouter } from "vue-router";
import TencentCloudChat from "@tencentcloud/chat";
import { showConfirmDialog, showToast, showDialog } from "vant";
const store = useStore();
const router = useRouter();
const _txImSign = ref();
const userInfo = computed(() => store.state.userJs.userInfo);
const nickName = ref("");
const avatar = ref("");
const userLevel = ref(0);
function _getInit() {
  getInit()
    .then((res) => {
      store.commit("UPDATE_FILES_CDN_DOMAIN", res.data.filesCdnDomain);
      store.commit("getInitInfo", res.data);
      localStorage.setItem("AppID", res.data.imSdkappid * 1);
    })
    .then(() => {
      if (localStorage.getItem("Token")) {
        _getUserInfo();
        initIm();
      }
    });
}
const uid = ref();
function _getUserInfo() {
  getUserInfo()
    .then((res) => {
      uid.value = res.data.uid;
      localStorage.setItem("isLogin", true);
      store.commit("UPDATE_USER_INFO", res.data);
      store.commit("toggleAnchor", res.data.isAnchor == 1 ? true : false);
      store.commit("changYouliaoExpert", res.data.isYouliaoExpert);
      if (res.data.isPlatformAide == 1) {
        //是否是平台助理
        store.commit("changeUserIdentity", 3);
      } else {
        store.commit("changeUserIdentity", 1);
      }
      nickName.value = res.data.nickName;
      avatar.value = res.data.avatar;
      userLevel.value = res.data.userLevel;
    })
    .then(() => {
      getTxImSign({ imUid: uid.value.toString() }).then((result) => {
        _txImSign.value = result.data.txImSign;
        chat()
          .login({
            userID: uid.value.toString(),
            userSig: _txImSign.value,
          })
          .then((result) => {})
          .catch((err) => {});
      });
    });
}
function initIm() {
  // console.log(78789);
  //im初始化   消息组件的im
  //imReady 消息组件和直播间公用
  chat().on(TencentCloudChat.EVENT.SDK_READY, imReady);
}
function onKickedOut() {
  store.commit("UPDATE_USER_INFO", {});
  store.commit("toggleAnchor", false);
  store.commit("changeUserIdentity", 1);
  store.commit("changYouliaoExpert", 0);
  store.commit("changeUnreadCount", 0);
  localStorage.removeItem("Token");
  localStorage.removeItem("isLogin");
  showConfirmDialog({
    title: "提示",
    message: "您的账号在其他地方登录了，是否重新登录？",
  })
    .then(() => {
      router.push({
        name: "Login",
      });
    })
    .catch(() => {
      window.location.reload();
    });
}
function imReady(e) {
  if (e.name != "sdkStateReady") {
    //没有ready 重新登陆
    chat()
      .login({
        userID: userInfo.uid.toString(),
        userSig: _txImSign.value,
      })
      .then((result) => {});
    return;
  }
  chat()
    .updateMyProfile({
      nick: userInfo.value.nickName,
      avatar: userInfo.value.avatar ? userInfo.value.avatar : "",
      level: userInfo.value.userLevel,
    })
    .then((res) => {
      // console.log("我看啊可能更新了用户的信息么有");
    })
    .catch((err) => {
      // console.log("用户信息失败errerr:" + err);
      // console.log(userInfo.value);
    });
  // setTimeout(() => {
  //   chat()
  //     .getConversationList()
  //     .then((res) => {
  //       _getTotalUnreadMessageCount();
  //     });
  // }, 1000);
}
function _getTotalUnreadMessageCount() {
  let totalUnreadCount = chat().getTotalUnreadMessageCount();

  store.commit("changeUnreadCount", totalUnreadCount);
}
function _receiveMsg(event) {
  // console.log(event);
  event.data.forEach((element) => {
    if (element.type == "TIMCustomElem") {
      let _data = JSON.parse(element.payload.data);
      if (_data.action == "AccountNotify" && _data.data.status == 1) {
        chat().logout();
        localStorage.removeItem("Token");
        localStorage.removeItem("isLogin");
        showDialog({
          title: "提示",
          message: "您的账号被封禁了",
        }).then(() => {
          router
            .replace({
              name: "Home",
            })
            .then(() => {
              window.location.reload();
            });
        });

        return;
      }
    }
  });
}
onBeforeMount(() => {
  getSensitiveWords().then((res) => {
    if (!res.data) return;
    let arr = res.data.sensitiveWords.split(",");
    store.commit("getSensitiveWords", arr);
  });
  _getInit();
});
//用户被踢下线
chat().on(TencentCloudChat.EVENT.KICKED_OUT, onKickedOut);
chat().on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, _receiveMsg);
onMounted(() => {});
watch(
  () => store.state.userJs.userInfo,
  (val) => {
    console.log(val);
    console.log("我看看进来了没有");
    if (val.uid) {
      initIm();
    }
  }
);
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input,
textarea {
  caret-color: #d8ad6d;
}
/*
* 空数据的样式
*/
#app .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.empty_img {
  width: 140px;
}
.empty_text {
  color: #4b5563;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.back_box {
  z-index: 999;
  position: relative;
  height: 10.66vw;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 3.73vw;
}
.back_box > img {
  width: 5.33vw !important;
  height: 5.33vw !important;
}
.title {
  flex: 1;
  text-align: center;
  font-weight: 500;
  font-size: 4.26vw;
  color: #000;
}
.save {
  font-size: 4vw;
  font-weight: 400;
  color: #000000;
  opacity: 0.25;
}
.active {
  color: #d8ad6d;
  opacity: 1 !important;
}
.html_box {
  background-color: #fff;
  height: calc(100vh - 10.66vw - 28px);
  padding: 14px;
  text-align: left;
  font-size: 13px;
  overflow-y: scroll;
}
</style>
