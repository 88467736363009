import axios from "axios";
import { showFailToast, showConfirmDialog } from "vant";
// import { useStore } from "vuex";
import store from "../store/index";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 100000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("Token")) {
      config.headers["Authorization"] = localStorage.getItem("Token"); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?";

      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }

    return config;
  },
  (error) => {
    // console.log(error);/
    Promise.reject(error);
  }
);
// const store = useStore();
// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const code = res.data.code;
    const message = res.data.msg;

    if (code === 401) {
      localStorage.removeItem("Token");
      localStorage.removeItem("isLogin");
      store.commit("UPDATE_USER_INFO", {});
      showConfirmDialog({
        title: "提示",
        message: "未登录，是否先登录",
      })
        .then(() => {
          location.href = "/login";
        })
        .catch(() => {});
      return Promise.reject("登录状态已过期，请重新登录!");
    } else if (code != 200) {
      showFailToast(message ? message : "请求失败");
      return Promise.reject(new Error(message));
    }

    return res.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
import { showToast, showLoadingToast, closeToast } from "vant";

export function upLoad(params) {
  showLoadingToast({
    duration: 0,
    forbidClick: true,
    message: "上传中…",
  });
  return service
    .post("/file/upload", params, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        platform: "0",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      closeToast();
      return res.data;
    })
    .catch(() => {
      showFailToast("上传失败");
      closeToast();
    });
}

export default service;
