import request from "@/untils/request.js";
//全部
export function allFootBallMatch(params) {
  return request({
    url: "/live/match/football/all",
    method: "get",
    params,
  });
}
//热门
export function hotFootBallMatch(params) {
  return request({
    url: "/live/match/football/hot",
    method: "get",
    params,
  });
}
////赛程
export function courseFootBallMatch(params) {
  return request({
    url: "/live/match/football/course",
    method: "get",
    params,
  });
}
///赛果
export function resultFootBallMatch(params) {
  return request({
    url: "/live/match/football/result",
    method: "get",
    params,
  });
}
//进行
export function playingFootBallMatch(params) {
  return request({
    url: "/live/match/football/playing",
    method: "get",
    params,
  });
}
///字母筛选
export function letterSortFootBallMatch(params) {
  return request({
    url: "/live/match/football/competition/letterSort/list",
    method: "get",
    params,
  });
}
//赛况文字直播
export function footballTliveList(params) {
  return request({
    url: "/live/match/football/tlive/list",
    method: "get",
    params,
  });
}
//赛事详情
export function footballInfo(matchId) {
  return request({
    url: "/live/match/football/info/" + matchId,
    method: "get",
    params :{
      platform:0
    }
  });
}
