<template>
  <div class="msg">
    <div class="top">
      <TopBack :title="'消息'" :backShow="false" />
    </div>
    <div class="empty" v-if="!isLogin">
      <img src="@/assets/img/home/empty1.png" alt="" class="empty_img" />
      <div class="empty_text">您还未登陆，无法查看消息</div>
      <div class="login-btn" @click="goLogin">立即登陆</div>
    </div>
    <LoadingCom v-else-if="loading" />
    <div v-else>
      <div class="empty" v-if="conversationList.length == 0 && systemMsgList.length == 0">
        <img src="@/assets/img/home/empty.png" alt="" class="empty_img" />
        <div class="empty_text">暂无消息</div>
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-else>
        <van-list
          class="msg_box"
          @load="onLoad"
          finished-text="没有更多了"
          :finished="isSyncCompleted"
          v-model:loading="loading"
        >
          <!-- 系统消息 -->
          <MsgItem
            v-for="(item, j) in systemMsgList"
            :key="j"
            :item="item"
            :type="1"
            @click="goDetail(1, item)"
          />
          <div v-for="(item, i) in conversationList" :key="item.conversationID">
            <MsgItem :item="item" :j="i" @click="goDetail(2, item)" :type="2" />
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="download_box">
      <DownloadApp></DownloadApp>
    </div>
    <TabBar></TabBar>
  </div>
</template>

<script setup name="Msg">
import { showToast } from "vant";
import { chat } from "@/untils/tim";
import TencentCloudChat from "@tencentcloud/chat";
import MsgItem from "./components/msgItem.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch, watchEffect } from "vue";
const store = useStore();
const router = useRouter();
const refreshing = ref(false);
const conversationList = ref([]); //会话列表
const systemMsgList = ref([]); //系统消息
const isSyncCompleted = ref(false); //会话列表是否拉完
const loading = ref(true);
const isLogin = ref(localStorage.getItem("isLogin"));
function goDetail(i, item) {
  localStorage.setItem("imChatType", item.type);
  if (item.type == "GROUP") {
    localStorage.setItem("imChatId", item.groupProfile.groupID);
  } else {
    localStorage.setItem("imChatId", item.userProfile.userID);
  }
  if (item.unreadCount > 0) {
    chat()
      .setMessageRead({
        conversationID: item.conversationID,
      })
      .then((res) => {
        nextTick(() => {
          item.unreadCount = 0;
        });
      });
  }
  if (i == 1) {
    router.push({
      name: "SystemMsg",
    });
  } else {
    router.push({
      name: "MsgDetails",
    });
  }
}
function onRefresh() {
  isSyncCompleted.value = true;
  _getConversationList();
}
function onLoad() {
  if (!isSyncCompleted.value) {
    _getConversationList();
  }
}
function _getConversationList() {
  //拉取会话列表
  chat()
    .getConversationList()
    .then((imResponse) => {
      let arr = imResponse.data.conversationList.filter((item) => item.type != "@TIM#SYSTEM");
      let arr1 = arr.filter(
        (item) =>
          item.conversationID.indexOf("GUEST_pc_") < 0 &&
          item.conversationID.indexOf("GUEST_h5_") < 0 &&
          item.conversationID.indexOf("systemadmin") < 0
      );
      let arr2 = arr1.filter(
        (item) =>
          (item.type == "GROUP" && item.groupProfile.type != "AVChatRoom") ||
          (item.type == "C2C" && item.userProfile.userID != "admin")
      );
      let systemadminArr = imResponse.data.conversationList.filter(
        (item) => item.type == "C2C" && item.userProfile.userID == "systemadmin"
      );
      systemMsgList.value = systemadminArr;
      conversationList.value = arr2;
      isSyncCompleted.value = imResponse.data.isSyncCompleted;
    })
    .catch((err) => {
      // showToast("您的账号已在其他地方登录");
      localStorage.removeItem("isLogin");
      localStorage.removeItem("Token");
      store.commit("UPDATE_USER_INFO", {});
      store.commit("changeUnreadCount", 0);
    });
}

function onConversationListUpdated(event) {
  // console.log(event);
  ///监听会话列表更新
  let arr = event.data.filter((item) => item.type != "@TIM#SYSTEM");
  let arr1 = arr.filter(
    (item) =>
      item.conversationID.indexOf("GUEST_pc_") < 0 &&
      item.conversationID.indexOf("GUEST_h5_") < 0 &&
      item.conversationID.indexOf("systemadmin") < 0
  );
  let arr2 = arr1.filter(
    (item) =>
      (item.type == "GROUP" && item.groupProfile.type !== "AVChatRoom") ||
      (item.type == "C2C" && item.userProfile.userID != "admin")
  );
  let systemadminArr = event.data.filter(
    (item) => item.type == "C2C" && item.userProfile.userID == "systemadmin"
  );
  nextTick(() => {
    systemMsgList.value = systemadminArr;
    conversationList.value = arr2;
  });
}
function goLogin() {
  router.push({
    name: "Login",
  });
}
chat().on(TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);
onBeforeUnmount(() => {
  // console.log("取消会话列表监听");
  chat().off(TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);
});
onMounted(() => {
  setTimeout(function () {
    _getConversationList();
    loading.value = false;
  }, 1000);
});
</script>

<style scoped>
.top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 99;
}
.msg {
  min-height: 100vh;
}
.van-loading {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty {
  padding: 100px;
  height: calc(100vh - 200px);
}
.msg_box {
  padding: 10.66vw 0 30.93vw;
}
.msg_box::-webkit-scrollbar {
  display: none;
}
.download_box {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 14.93vw;
}
.login-btn {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 4.26vw;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 42.66vw;
  height: 12.8vw;
  border-radius: 7.2vw;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(283deg, #d8ad6d 9.2%, #f5d6a8 90.8%);
}
</style>
