<template>
  <div class="match">
    <div class="match_top">
      <!-- 筛选按钮 -->
      <img
        src="../../assets/img/icon/icon2.png"
        alt=""
        class="screen_icon"
        @click="visible = true"
      />
      <!-- 球类tab -->
      <div class="match_tab">
        <div class="tab_item" v-for="item in tabList" :key="item.id" @click="changeTab(item.id)">
          <p :class="item.id == tabActive ? 'active' : 'opacity'">{{ item.text }}</p>
          <div class="line" :class="item.id == tabActive ? 'active_line' : ''"></div>
        </div>
      </div>
      <!-- 刷新按钮 -->
      <img
        src="../../assets/img/icon/icon3.png"
        alt=""
        class="refresh_icon"
        :class="{ rotate360: showAnimate }"
        @animationend="reset"
        @click="play"
      />
    </div>
    <div class="match_nav">
      <!-- 赛程类型 -->
      <div class="match_nav_list">
        <div
          class="match_nav_item"
          v-for="child in navList"
          :key="child.id"
          @click="changeNav(child.id)"
          :class="{
            nav_active: navActive == child.id,
          }"
        >
          {{ child.text }}
        </div>
      </div>
      <!-- 周 -->
      <div class="match_week" v-if="navActive == 3 || navActive == 4">
        <div
          class="match_week_item"
          v-for="(childs, x) in weekList"
          :key="x"
          :class="{
            week_active: weekActive == x,
          }"
          @click="changeWeek(x, childs)"
        >
          <div
            v-if="childs.alldate != format(new Date(), 'yyyy-MM-dd')"
            class="match_week_item_date"
          >
            {{ childs.date }}
          </div>
          <div class="week">
            {{ childs.week }}
          </div>
        </div>
      </div>
    </div>
    <!--足球数据  -->
    <div class="match_container" v-if="tabActive == 0">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="ballMatchList.length > 0 ? '没有更多了' : ''"
        @load="onLoad"
        :offset="150"
      >
        <div v-if="ballMatchList.length > 0">
          <FootballMatchItem
            v-for="(item, j) in ballMatchList"
            :key="item.id"
            :j="j"
            :item="item"
          ></FootballMatchItem>
        </div>
        <div class="empty" v-if="ballMatchList.length == 0 && finished">
          <img src="@/assets/img/icon/empty.png" alt="" class="empty_img"/>
          <div class="text">暂无比赛</div>
        </div>
      </van-list>
    </div>
    <!-- 篮球数据 -->
    <div class="match_container" v-else>
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="ballMatchList.length > 0 ? '没有更多了' : ''"
        @load="onLoad"
        :offset="150"
      >
        <div v-if="ballMatchList.length > 0">
          <BasketballMatchItem
            v-for="(item, k) in ballMatchList"
            :key="item.id"
            :j="k"
            :item="item"
          ></BasketballMatchItem>
        </div>
        <div class="empty" v-if="ballMatchList.length == 0 && finished">
          <img src="@/assets/img/icon/empty.png" alt="" class="empty_img"/>
          <div class="text">暂无比赛</div>
        </div>
      </van-list>
    </div>
    <van-overlay :show="visible" @click.stop="">
      <GameScreen
        @close="handleClose"
        :tabActive="tabActive"
        :visible="visible"
        @selCompetitionIds="selCompetitionIds"
      />
    </van-overlay>
    <div class="download_box">
      <DownloadApp></DownloadApp>
    </div>
    <TabBar></TabBar>
  </div>
</template>

<script setup name="Match">
import GameScreen from "../gameScreen/index.vue";
import { ref, onMounted } from "vue";
import FootballMatchItem from "./components/football.vue";
import BasketballMatchItem from "./components/basketball.vue";
import { format, getWeek } from "@/untils/index";
import { useRouter } from "vue-router";
const visible = ref(false);
const router = useRouter();
const tabActive = ref(0);
const showAnimate = ref(false);
const loading = ref(false);
const finished = ref(false);
const list = ref([]);

const tabList = ref([
  {
    text: "足球",
    id: 0,
  },
  {
    text: "篮球",
    id: 1,
  },
]);
const navActive = ref(0);
const navList = ref([
  {
    text: "全部",
    id: 0,
  },
  {
    text: "热门",
    id: 1,
  },
  {
    text: "进行",
    id: 2,
  },
  {
    text: "赛程",
    id: 3,
  },
  {
    text: "赛果",
    id: 4,
  },
]);
const weekList = ref([]);
const weekActive = ref(0);
function selCompetitionIds(str) {
  visible.value = false;
  queryParams.value.pageNum = 1;
  ballMatchList.value = [];
  finished.value = false;
  queryParams.value.competitionIds = str;
  onLoad();
}
function handleClose() {
  visible.value = false;
}
function changeTab(id) {
  tabActive.value = id;
  navActive.value = 0;
  //数据初始化
  queryParams.value.pageNum = 1;
  ballMatchList.value = [];
  queryParams.value.competitionIds = null;
  finished.value = false;
  weekActive.value = 0;
  loading.value = false;
  onLoad();
}
function changeNav(id) {
  //切换类型时，数据初始化
  weekList.value = [];
  if (id == 3) {
    weekActive.value = 0;
    getWeekDates();
  }
  if (id == 4) {
    weekActive.value = 6;
    getWeekDates2();
  }
  navActive.value = id;

  queryParams.value.pageNum = 1;
  finished.value = false;
  ballMatchList.value = [];
  queryParams.value.competitionIds = null;
  onLoad();
}
function changeWeek(i, item) {
  weekActive.value = i;
  queryParams.value.pageNum = 1;
  ballMatchList.value = [];
  finished.value = false;
  onLoad();
}
function play() {
  showAnimate.value = true;
  //初始化数据
  queryParams.value.pageNum = 1;
  ballMatchList.value = [];
  finished.value = false;
  queryParams.value.competitionIds = null;
  //刷新，重新请求数据
  onLoad();
}
function reset() {
  showAnimate.value = false;
}
function getWeekDates() {
  weekActive.value = 0; // 获取当前日期
  var today = new Date();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  weekList.value.push({
    date: (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day),
    week: "今日",
    alldate: format(new Date(), "yyyy-MM-dd"),
  });

  let item = {};
  for (let i = 1; i < 7; i++) {
    let week = new Date(today.getFullYear(), today.getMonth(), today.getDate() + i).getDay();
    let date = new Date(today.getFullYear(), today.getMonth(), today.getDate() + i);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    item = {
      week: getWeek(week),
      alldate: format(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() + i),
        "yyyy-MM-dd"
      ),
      date: (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day),
    };
    weekList.value.push(item);
  }
}
function getWeekDates2() {
  weekActive.value = 6;
  var today = new Date();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  weekList.value.push({
    date: (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day),
    week: "今日",
    alldate: format(new Date(), "yyyy-MM-dd"),
  });
  // 获取当前日期
  let item = {};
  let nextWeek = [];

  for (let i = 1; i < 7; i++) {
    let week = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i).getDay();
    let date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    item = {
      week: getWeek(week),
      alldate: format(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() - i),
        "yyyy-MM-dd"
      ),
      date: (month < 10 ? "0" + month : month) + "/" + (day < 10 ? "0" + day : day),
    };
    nextWeek.push(item);
  }
  weekList.value = nextWeek.reverse().concat(weekList.value);
}
onMounted(() => {});
function onLoad() {
  if (!finished.value) {
    loading.value = true;
    if (tabActive.value == 0) {
      switch (navActive.value) {
        case 0:
          queryParams.value.date = null;
          _allFootBallMatch();
          break;
        case 1:
          queryParams.value.date = null;
          _hotFootBallMatch();
          break;
        case 2:
          queryParams.value.date = null;
          _playingFootBallMatch();
          break;
        case 3:
          _courseFootBallMatch();
          break;
        case 4:
          _resultFootBallMatch();
          break;
        default:
          _allFootBallMatch();
          return;
      }
    }
    if (tabActive.value == 1) {
      switch (navActive.value) {
        case 0:
          queryParams.value.date = null;
          _allBasketballMatch();
          break;
        case 1:
          queryParams.value.date = null;
          _hotBasketballMatch();
          break;
        case 2:
          queryParams.value.date = null;
          _playingBasketballMatch();
          break;
        case 3:
          _courseBasketballMatch();
          break;
        case 4:
          _resultBasketballMatch();
          break;
        default:
          _allBasketballMatch();
          return;
      }
    }
  }
}
const queryParams = ref({
  pageSize: 20,
  pageNum: 1,
});
/**************************************足球的请求数据***************************************** */
import {
  allFootBallMatch,
  hotFootBallMatch,
  courseFootBallMatch,
  resultFootBallMatch,
  playingFootBallMatch,
} from "@/assets/api/footBallMatch";
const ballMatchList = ref([]);
function _allFootBallMatch() {
  allFootBallMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
    })
    .catch((err) => {
      loading.value = false;
    });
}
function _hotFootBallMatch() {
  hotFootBallMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
    })
    .catch((err) => {
      loading.value = false;
    });
}
function _courseFootBallMatch() {
  queryParams.value.date = weekList.value[weekActive.value].alldate;
  courseFootBallMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
      // console.log(ballMatchList.value.length);
    })
    .catch((err) => {
      loading.value = false;
    });
}
function _resultFootBallMatch() {
  queryParams.value.date = weekList.value[weekActive.value].alldate;
  resultFootBallMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
      // console.log(ballMatchList.value.length);
    })
    .catch((err) => {
      loading.value = false;
    });
}
function _playingFootBallMatch() {
  playingFootBallMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
      // console.log(ballMatchList.value.length);
    })
    .catch((err) => {
      loading.value = false;
    });
}
/**************************************篮球的请求数据*************************************************** */
import {
  allBasketballMatch,
  hotBasketballMatch,
  courseBasketballMatch,
  resultBasketballMatch,
  playingBasketballMatch,
} from "@/assets/api/basketBallMatch";

const basketBallMatchList = ref([]);
function _allBasketballMatch() {
  allBasketballMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
      // console.log(ballMatchList.value.length);
    })
    .catch((err) => {
      loading.value = false;
    });
}
function _hotBasketballMatch() {
  hotBasketballMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
    })
    .catch((err) => {
      loading.value = false;
    });
}
function _courseBasketballMatch() {
  queryParams.value.date = weekList.value[weekActive.value].alldate;
  courseBasketballMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
      // console.log(ballMatchList.value.length);
    })
    .catch((err) => {
      loading.value = false;
    });
}
function _resultBasketballMatch() {
  queryParams.value.date = weekList.value[weekActive.value].alldate;
  resultBasketballMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
      // console.log(ballMatchList.value.length);
    })
    .catch((err) => {
      loading.value = false;
    });
}
function _playingBasketballMatch() {
  playingBasketballMatch(queryParams.value)
    .then((res) => {
      ballMatchList.value = ballMatchList.value.concat(res.data);
      loading.value = false;
      if (ballMatchList.value.length == res.total) {
        finished.value = true;
      } else {
        queryParams.value.pageNum++;
      }
       //console.log(ballMatchList.value.length);
    })
    .catch((err) => {
      loading.value = false;
    });
}
</script>

<style scoped>
.download_box {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 14.93vw;
}
.match {
  background-color: #fafafa;
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.match_top {
  background-color: #fff;
  padding: 8px 2.66vw;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f3f5f6;
}
.match_top img {
  width: 6.5vw;
  height: 6.5vw;
}
.match_tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab_item {
  font-size: 4.8vw;
  line-height: 5.86vw;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tab_item:nth-child(1) {
  margin-right: 11.46vw;
}
.active {
  font-weight: 600;
}
.opacity {
  opacity: 0.45;
}
.line {
  width: 4.26vw;
  height: 4px;
  border-radius: 2px;
  background: transparent;
  flex-shrink: 0;
}
.active_line {
  background: #d8ad6d !important;
}

.match_nav {
  background-color: #fff;
  border-bottom-left-radius: 3.2vw;
  border-bottom-right-radius: 3.2vw;
}
.match_nav_list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px 10.66vw;
}
.match_nav_item {
  padding: 2px 2.66vw;
  color: #64748b;
  font-size: 14px;
}
.nav_active {
  background: #d8ad6d;
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
}

.match_week {
  padding: 6px 5.33vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid #f3f5f6;
}
.match_week_item {
  width: 9.6vw;
  height: 9.6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2.66vw;
  font-weight: 400;
  color: #9ca3af;
}
.week_active {
  background: #faf2eb;
  color: #d8ad6d;
  border-radius: 4px;
}

.rotate360 {
  animation: rotate360 1s ease-out 0s;
}

@keyframes rotate360 {
  100% {
    transform: rotate(360deg);
  }
}
.match_container {
  flex: 1;
  padding: 0 2.66vw 30.93vw 2.66vw;
  overflow-x: scroll;
}
.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty img {
  width: 42.66vw;
}
.text {
  font-size: 3.73vw;
  font-weight: 400;
  color: #4b5563;
  margin-bottom: 40px;
}
.match /deep/ .van-overlay {
  z-index: 999;
}
</style>
