const imModules = {
  state: {
    // imChatType: "", //点击  用户  C2C或者  群聊 GROUP  表示类型
    // imChatId: "", //点击的用户或者群聊的id
    // allProhibitioValue: false, //保存全体是否被禁言
    // userProhibitioValue: false, //保存我是否被禁言
    // ConversationProfile: {}, //当前会话资料
    sensitiveWords: [], //敏感词
    sendMsg: "",
    isCompleted: false, // 当前会话消息列表是否已经拉完了所有消息
    currentMessageList: [], //当前会话信息列表
    nextReqMessageID: "", //加载更多会话信息列表的ID， im返回

    unreadCount: 0, //im未读
  },
  mutations: {
    getSensitiveWords(state, arr) {
      state.sensitiveWords = arr;
    },
    changeUnreadCount(state,_int) {
      state.unreadCount = _int;
    },
    // getConversationProfileInfo(state, obj) {
    //   state.ConversationProfile = obj;
    // },
    // getAllProhibitioValue(state, bool) {
    //   state.allProhibitioValue = bool;
    // },
    // getUserProhibitioValue(state, bool) {
    //   state.userProhibitioValue = bool;
    // },
    // changeImChatType(state, str) {
    //   state.imChatType = str;
    // },
    // changeImChatId(state, id) {
    //   state.imChatId = id;
    // },
    UPDATE_SND_MSG(state, sendMsg) {
      state.sendMsg = sendMsg;
    },
    updateisCompleted(state, isCompleted) {
      state.isCompleted = isCompleted;
    },
    updatecurrentMessageList(state, currentMessageList) {
      state.currentMessageList = currentMessageList;
    },
  },
  actions: {
    updateSendMsg({ commit }, sendMsg) {
      commit("UPDATE_SND_MSG", sendMsg);
    },
  },
};
export default imModules;
