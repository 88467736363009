import request from "@/untils/request.js";
//个人主页
export function getUserInfo() {
  return request({
    url: "/live/myself/info",
    method: "get",
    params: {
      platform: 0,
    },
  });
}
//修改个人信息
export function editUserInfo(data) {
  return request({
    url: "/live/myself/modify",
    method: "post",
    data,
  });
}
//用户-获取其它用户基本信息
export function basicInfo(params) {
  return request({
    url: "/live/user/basicInfo",
    method: "get",
    params,
  });
}
//取消关注
export function cancelFollow(data) {
  return request({
    url: "/live/user/follow/cancel",
    method: "post",
    data,
  });
}
//关注用户
export function addFollow(data) {
  return request({
    url: "/live/user/follow/add",
    method: "post",
    data,
  });
}
//详情
export function protocolsInfo(params) {
  return request({
    url: "/live/protocols/info",
    method: "get",
    params,
  });
}
