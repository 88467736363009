import request from "@/untils/request.js";
//登录方法
export function logIn(data) {
  return request({
    url: "/auth/user/login",
    method: "post",
    data,
  });
}
//注册
export function register(data) {
  return request({
    url: "/auth/user/register",
    method: "post",
    data,
  });
}
//发送短信验证码
export function getCode(phone) {
  return request({
    url: "/live/sms/" + phone,
    method: "get",
    params: {
      platform: 0,
    },
  });
}
//修改密码
export function setPassword(data) {
  return request({
    url: "/live/myself/modify/password",
    method: "post",
    data
  });
}