<template>
  <div class="msg_item">
    <div class="avart" v-if="props.type == 1">
      <img src="@/assets/img/icon/systemnotify.png" />
      <div class="msg_num" v-if="props.item.unreadCount > 0">
        {{ props.item.unreadCount > 99 ? "99+" : props.item.unreadCount }}
      </div>
    </div>
    <div class="avart" v-else>
      <img
        :src="filesCdnDomain + props.item.groupProfile.avatar"
        v-if="props.item.type == 'GROUP' && props.item.groupProfile.avatar"
      />
      <img
        :src="filesCdnDomain + props.item.userProfile.avatar"
        alt=""
        v-else-if="props.item.type == 'C2C' && props.item.userProfile.avatar"
      />
      <img src="@/assets/img/icon/avart.png" alt=" " v-else />

      <div class="msg_num" v-if="props.item.unreadCount > 0">
        {{ props.item.unreadCount > 99 ? "99+" : props.item.unreadCount }}
      </div>
    </div>
    <div class="middle" v-if="props.type == 2">
      <div class="msg_name">
        <div class="name" v-if="props.item.type == 'GROUP'">{{ props.item.groupProfile.name }}</div>
        <div class="name" v-if="props.item.type == 'C2C'">{{ props.item.userProfile.nick }}</div>
        <div class="time">
          {{ item.lastMessage.lastTime ? imTime(item.lastMessage.lastTime) : "" }}
        </div>
      </div>
      <div class="msg_content" v-if="item.lastMessage.messageForShow">
        <div
          class="content"
          v-html="getMessageForShow(sensitiveWords, item.lastMessage)"
          v-if="!item.lastMessage.isRevoked"
        ></div>
        <div class="content" v-else>
          <span v-if="item.lastMessage.revoker == userInfo.uid">你</span>
          <span v-else-if="props.item.type == 'C2C'">对方</span>
          <span v-else>"{{ item.lastMessage.nick }}"</span>
          <span>撤回了一条消息</span>
        </div>

        <div
          class="icon"
          v-if="item.messageRemindType == TencentCloudChat.TYPES.MSG_REMIND_ACPT_NOT_NOTE"
        >
          <img src="@/assets/img/icon/icon7.png" alt="" />
        </div>
      </div>
    </div>
    <div class="middle" v-else>
      <div class="msg_name">
        <div class="name">系统消息</div>
        <div class="time"></div>
      </div>
      <div class="msg_content">
        <div class="content" v-if="props.item">
          {{
            props.item.lastMessage.payload && props.item.lastMessage.payload.description
              ? props.item.lastMessage.payload.description
              : "暂无消息"
          }}
        </div>
        <div class="content" v-else>暂无系统消息</div>
      </div>
    </div>
  </div>
</template>
<script setup name="MsgItem">
import { getMessageForShow } from "@/untils/tim";
// import { filterFn } from "@/untils/index";
import TencentCloudChat from "@tencentcloud/chat";
import { useStore } from "vuex";
import { computed } from "vue";
const store = useStore();
const userInfo = computed(() => store.state.userJs.userInfo);
const sensitiveWords = computed(() => store.state.imJs.sensitiveWords);
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const props = defineProps({
  type: {
    type: Number, //1  系统消息  2 im会话
    default: 1,
  },

  item: {
    type: Object,
    default: {},
  },
});
</script>

<style scoped>
.msg_item {
  padding: 3.73vw;
  display: flex;
  align-items: center;
}
.avart {
  width: 14.3vw;
  height: 12.8vw;
  margin-right: 2.66vw;
  position: relative;
}
.avart img {
  width: 12.8vw;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.msg_num {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 5px;
  border-radius: 2.4vw;
  background: #ef4444;
  color: #fff;
  font-family: PingFang SC;
  font-size: 2.93vw;
  font-weight: 400;
  line-height: 2.93vw; /* 100% */
}

.middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.msg_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.name {
  color: #222;
  font-family: PingFang SC;
  font-size: 4vw;
  font-weight: 400;
}
.time {
  color: rgba(0, 0, 0, 0.45);
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 400;
}

.msg_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content {
  margin-top: 5px;
  width: 68.8vw;
  text-align: left;
  color: rgba(0, 0, 0, 0.45);
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon {
  width: 4.8vw;
  height: 4.8vw;
}
.icon img {
  width: 100%;
  height: 100%;
}
</style>
