<template>
  <div class="back_box">
    <img src="../assets/img/icon/back.png" alt="" @click.stop="goBack" v-if="backShow" />
    <div class="title">{{ title }}</div>
    <div class="save" v-if="saveShow" @click="saveHandle" :class="values ? 'active' : ''">保存</div>
  </div>
</template>
<script setup name="topBack">
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps({
  values: "",
  title: String,
  saveShow: Boolean,
  backShow: {
    type: Boolean,
    default: true,
  },
});
watch(
  () => props.values,
  (newValue, oldValue) => {
    // console.log("新的值:", newValue);
    // console.log("旧的值:", oldValue);
  }
);
const emit = defineEmits(["saveBtn"]);
function goBack() {
  router.back();
}
function saveHandle() {
  if (!props.values) {
    return;
  }
  emit("saveBtn");
}
</script>
<style scoped>
.back_box {
  z-index: 999;
  position: relative;
  height: 10.66vw;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 3.73vw;
}
.back_box img {
  width: 5.33vw;
  height: 5.33vw;
}
.title {
  flex: 1;
  text-align: center;
  font-weight: 500;
  font-size: 4.26vw;
  color: #000;
}
.save {
  font-size: 4vw;
  font-weight: 400;
  color: #000000;
  opacity: 0.25;
}
.active {
  color: #d8ad6d;
  opacity: 1 !important;
}
</style>
